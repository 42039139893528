<template>
    <div class="main">
        <div class="header">
            <div class="left">
                <button style="background-color: rgb(16, 39, 46)" class="left-button" @click="ToMap()">
                    返回
                </button>
            </div>
            <p class="title">智 慧 平 台 数 据 驾 驶 舱</p>
            <div class="header-right">
                <div class="time">{{ time }}</div>
                <div class="avatar">
                    <el-popover placement="top" width="160" v-model="visible" trigger="hover">
                        <p style="padding: 10px 0px">确定要退出登录吗？</p>
                        <div style="text-align: right; margin: 0">
                            <el-button size="mini" type="text" @click="cancelLogout()">取消</el-button>
                            <el-button type="primary" size="mini" @click="logout()">确定</el-button>
                        </div>
                        <!-- <img src="../../assets/home/avatar.png" alt="" slot="reference" /> -->
                        <img style="width:2.5rem;border-radius: 50%;border-color: black;" src="../../assets/home/logo.jpg" alt="" slot="reference" />
                    </el-popover>
                </div>
            </div>
        </div>
        <!-- 图片展示区 -->
        <div class="imghidden">
            <div class="showimg">
                <div class="imgbox">
                    <img v-for="(item, i) in list" :key="i" :src="item.photoUrl" alt="">
                </div>
            </div>
        </div>



    </div>
</template>
<script>


export default {
    data() {
        return {
            visible: false,
            // 当前时间
            time: '',
            list:[],
        };
    },
    created() {
        let that = this;
        // @ts-ignore
        this.setTime = setInterval(() => {
            that.Date();
        }, 1000);
    },
    mounted() {
        this.deviceImage()

    },
    methods: {
    // 设备图片
    deviceImage() {
      var param = {
        deviceId: Number(localStorage.getItem("deviceId")),
        pageNum:1,
        pageSize:20
      };
      this.$post("/devicePhoto/pageList", param)
        .then((res) => {
          // 主要参数
        //   console.log(res);
          if (res.code == 1) {
           this.list = res.data.list;
            
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });

    },
        // 返回按钮
        ToMap() {
            this.$router.push("/Citydevice");
        },
        // 获取时间戳
        Date() {
            const nowDate = new Date();
            const date = {
                year: nowDate.getFullYear(),
                month: nowDate.getMonth() + 1,
                date: nowDate.getDate(),
                hours: nowDate.getHours(),
                minutes: nowDate.getMinutes(),
                seconds: nowDate.getSeconds(),
            };
            const newmonth = date.month >= 10 ? date.month : "0" + date.month;
            const newday = date.date >= 10 ? date.date : "0" + date.date;
            const newHours = date.hours >= 10 ? date.hours : "0" + date.hours;
            const newminutes = date.minutes >= 10 ? date.minutes : "0" + date.minutes;
            const newseconds = date.seconds >= 10 ? date.seconds : "0" + date.seconds;

            this.time =
                date.year +
                "年" +
                newmonth +
                "月" +
                newday +
                "日" +
                newHours +
                ":" +
                newminutes +
                ":" +
                newseconds;
        },
        // 退出登录
        logout() {
            //   window.sessionStorage.clear();
            //   this.clearCookie();
            this.$get("/user/logout")
                .then((res) => {
                    if (res.code == 1) {
                        this.$router.push("/Login");
                        this.$message({
                            type: "success",
                            message: "登出成功!",
                        });
                    } else {
                        this.$message.error(res.msg);
                    }
                })
                .catch(() => {
                    console.log("异常");
                });
        },
        // 退出登录取消
        cancelLogout() {
            this.visible = false;
            this.$message({
                type: "info",
                message: "已取消登出",
            });
        },

    },
    beforeDestroy() {
        // 清除定时器
        if (this.setTime) {
            clearInterval(this.setTime);
        }
    },
};
</script>
  
<style lang="scss" scoped>
.main {
    background: url("../../assets/drive/big-screen-backimage.svg");
    margin: 0px; //外边距0
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
    zoom: 1;
    background-color: #07080e;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
    color: #fff;

    //   头部
    .header {
        display: flex;

        .left {
            position: absolute;
            top: 3.5vh;
            left: 1.563vw;
            display: flex;
            align-items: center;

            .left-button {
                display: inline-block;
                height: 4vh;
                line-height: 4vh;
                border: 1px solid rgb(11, 166, 167);
                box-shadow: inset 0px 1px 17px rgb(11, 166, 167);
                // background: #000;
                color: rgb(195, 226, 249);
                font-size: 1.04vw;
                border-radius: 7px;
                padding: 0 0.52vw;
                white-space: nowrap;
                box-sizing: border-box;
            }
        }

        .title {
            width: 100%;
            text-align: center;
            // margin-left: -0.5vw;
            background: linear-gradient(180deg,
                    rgba(238, 238, 238, 1),
                    rgba(113, 244, 236, 1));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            // text-fill-color: transparent;
            font-family: PingFang SC;
            font-size: 2.4vw;
            cursor: pointer;
        }

        .header-right {
            position: absolute;
            right: 1.563vw;
            top: 3.5vh;
            width: 25%;
            display: flex;
            align-items: center;

            .time {
                // margin-left: 20px;
                width: 80vw;
                text-align: right;
                color: rgb(195, 226, 249);
                font-family: PingFang;
                font-size: 1.04vw;
                line-height: 26px;
                letter-spacing: 0px;
                cursor: pointer;
            }

            .avatar {
                margin: 0 0 0 15px;
                width: 10vw;
            }
        }
    }

    // 图片展示区
    .imghidden {
        position: absolute;
        top: 13vh;
        width: 100vw;
        overflow-x: hidden;
        align-items: center;
    }

    .showimg {
        display: flex;
        width: calc(100vw + 20px);
        align-items: center;
        text-align: center;
        overflow-y: scroll;
        padding: 0 10vw;
        height: 80vh;
        box-sizing: border-box;
        // float: left;
        .imgbox {
            display: flex;
            width: 100%;
            align-items: center;
            text-align: center;
            justify-content: space-around;
            flex-wrap: wrap;
            height: 80vh;
            // float: left;
            img {
                margin: 2vh 0vw;
                width: 21vw;
                height: auto;
            }
        }
    }
}
</style>
  
  